import React, { Component } from "react";
import axios from "axios";
import envConfig from "../environment-property/property.json";
import TimeApproveMainPage from "./menuComponents/timeApproveMainPage";

import nextArrow from "../logo/next.png";
// import backArrow from "../logo/arrow-left.png";

import moment from "moment";
import {
  getErrorMessage,
  getProjectMethod,
  getTeamMemberDataMethod,
  patchMethod,
} from "../utils/utils";

import {
  fetchData,
  getBarDetails,
  getPieChartDetails,
} from "../utils/services";
import ConfirmApprovedTimeforBillingModel from "./commonComponents/confirmApprovedTimeforBillingModel";
import CheckInLoadPopup from "./commonComponents/checkInLoadPopup";
import { getPendingConfirmationList } from "../utils/data";
import ErrorModel from "./commonComponents/errorModel";
import Feedback from "./utilComponents/feedback";
import Banner from "./utilComponents/banner";

class MainMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // bannerVisible: !document.cookie.includes("chk_ta_info_banner=true"),
      // feedbackVisible: !document.cookie.includes("chk_ta_feedback=true"),
      bannerVisible: envConfig.banner.showBanner,
      feedbackVisible: envConfig.showFeedback,
      error: {
        status: false,
        message: "",
      },
      mounted: false,
      model: false,
      item: "", //-> css purpose ->  selected Element [action] -> highlighted selected project/ team member/ task

      projectList: [], // mainPayload -> project list -> updated from getAllocatedProjectList() method
      /**
       * teamMemberCard.jsx, taskCard.jsx, timeEntryCard.jsx depends on the values of "selectedProject" state
       * value updating -> 1. every click of left panel project list
       *  [ project item click]-> type -> project
       * [ teamMember item click]-> type -> teamMember
       * [ task item click]-> type -> task
       *
       * 2. day card checkbox selections
       */
      selectedProject: {
        selectProject: {}, //------------ required to identify which project item has selected
        type: "initial", //"initial" ->[required to disable "Apprvoe Selection" "Reject Selection" and "Confirm Approve Time for Billing" <- Until total time loading] "project" "teamMember"  "task" //----------------------------- TODO ->check -> enum -> for const values
        gteProjectId: 0,
        gteEmployeeNo: 0,
        taskId: 0,
      },
      selectedDate: {
        // get and set current date, year and week
        firstDayofCurrentWeek: moment().startOf("isoweek").format("YYYY-MM-DD"),
        year: moment().format("YYYY"),
        week: moment().format("WW"),
        dateOfFirstDayofCurrentWeek: moment()
          .startOf("isoweek")
          .format("DD MMMM"),
        dateOfLastDayofCurrentWeek: moment().endOf("isoweek").format("DD MMMM"),
        days: this.createListofDays(
          moment().startOf("isoweek").format("YYYY-MM-DD")
        ),
      },
      temp: {},
      loading: false,
      totalTimeStatus: false,
      // approveRejectTotal: {
      //   approvedTime: 100,
      //   rejectedTime: 150,
      // },
      dataForBarChart: {},
      // ModelState: false,
      setPieChartData: false,
      setBarChartData: false,
      toast: true,
      move: false,
    };
    this.lastRequestId = null; // required to set time delay to avoid calling getAllocatedProjectList() method (when -> Quick clicking (Arrow buttons for next week or past week) for doing week changes  )
  }

  componentDidMount() {
    this.getAllocatedProjectList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedDate.week !== this.state.selectedDate.week) {
      this.setState({
        mounted: false,
        totalTimeStatus: false,
      });
      // this.getAllocatFedProjectList();
      const requestId = `REQUEST-${Date.now()}`;
      this.lastRequestId = requestId;
      /**
       * set time delay to call getAllocatedProjectList()
       * [avoid unneccessary call for getAllocatedProjectList(), when do quick changes of week ]
       */
      setTimeout(() => {
        if (this.lastRequestId !== requestId) {
          return;
        } else {
          this.getAllocatedProjectList();
        }
      }, 2000);
    }
    if (
      prevState.selectedProject?.selectProject !==
      this.state.selectedProject?.selectProject
    ) {
      this.getBarChartData();
      this.getPieChartData();
    }
  }
  componentWillUnmount() {
    // Abort lazy loading when week change
    this.setState({
      mounted: false,
    });
  }

  /**
   * getAllocatedProjectList() Method -> API call to get Main payload (Project List )
   *    -> data fetch to the Project List (Left side of UI)
   *  -> After fetch project list,
   * iterate project list to get TeamMembers & Task list of each project
   * (use -> getTeamMemberAndTaskList() method)
   */

  getAllocatedProjectList = () => {
    this.setState({
      item: "",
      model: false,
      selectedProject: {
        selectProject: {},
        type: "initial",
        gteProjectId: 0,
        gteEmployeeNo: 0,
        taskId: 0,
        totalTimeStatus: false,
      },
    });

    getProjectMethod(
      envConfig.services.checkinBffServiceWebApproval,
      envConfig.services.userProjectDetails,
      "?user-name=",
      this.props.loggedUser.email,
      "&year=",
      this.state.selectedDate.year,
      "&week-no=",
      this.state.selectedDate.week
    )
      .then((response) => {
        if (response?.data?.projects?.length > 0) {
          let projectList = response?.data?.projects.sort((a, b) =>
            a.projectName > b.projectName ? 1 : -1
          );
          let selectedProject = this.state.selectedProject;
          this.setState({ projectList: [] });
          this.setState({
            projectList: projectList,
            mounted: true,
            selectedProject: {
              ...selectedProject,
              selectProject: projectList[0],
            },
          });

          let projects = response?.data?.projects;
          if (projects.length > 0) {
            // this.approach1(projects);
            this.approach2(projects); // lazy loading
          }
        }
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          loading: false,
          error: {
            status: true,
            message: message,
          },
        });
      });
  };

  approach2 = (projects) => {
    let promises = [];
    projects.map((project) => {
      promises.push(
        fetchData(
          project,
          this.state.selectedDate.year,
          this.state.selectedDate.week,
          this.props.loggedUser
        )
      );
    });

    if (promises.length > 0) {
      this.readFetchDataValues(promises);
    }
  };

  getPieChartData = () => {
    if (this.state.selectedProject.type !== "initial") {
      this.setState({
        setPieChartData: false,
      });
      let project = this.state.selectedProject.selectProject;
      let promises = [];

      promises.push(
        getPieChartDetails(
          project,
          this.state.selectedDate.year,
          this.state.selectedDate.week,
          this.props.loggedUser
        )
      );

      if (promises.length > 0) {
        this.setApproveRejectDetails(promises);
      }
    }
  };
  setApproveRejectDetails = (promises) => {
    Promise.all(promises).then((promise) => {
      promise?.map((obj) => {
        if (
          obj.week === this.state.selectedDate.week &&
          obj.year === this.state.selectedDate.year
        ) {
          let selectedProject = this.state.selectedProject;
          selectedProject.selectProject.approveRejectDetails =
            obj.approveRejectDetails;
          this.setState({
            selectedProject: selectedProject,
            setPieChartData: true,
          });
        }
      });
    });
  };

  readFetchDataValues = (promises) => {
    Promise.all(promises).then((promise) => {
      let newProjectList = [];
      promise?.map((obj) => {
        if (
          obj.week === this.state.selectedDate.week &&
          obj.year === this.state.selectedDate.year
        ) {
          newProjectList = [...newProjectList, obj.project];
        }
      });
      if (newProjectList.length > 0) {
        newProjectList = [...newProjectList].sort((a, b) =>
          a.totalTime < b.totalTime
            ? 1
            : a.totalTime === b.totalTime
            ? a.projectName > b.projectName
              ? 1
              : -1
            : -1
        );
        let selectedProject = this.state.selectedProject;
        if (this.state.mounted) {
          this.setState({
            totalTimeStatus: true,

            selectedProject: {
              ...selectedProject,
              selectProject: newProjectList[0],
              type: "project",
            },
            item: newProjectList[0]?.gteProjectId,
            projectList: newProjectList?.map((project) => {
              if (project.totalTime > 0) {
                if (project?.pendingConfirmations.length > 0) {
                  let newTeamMemberList =
                    this.mergePendingApprovalAndPending_Confirmation_List(
                      project.teamMembers,
                      project.pendingConfirmations
                    );
                  return {
                    ...project,
                    teamMembers: newTeamMemberList.sort((a, b) =>
                      a.name > b.name ? 1 : -1
                    ),
                  };
                } else {
                  return project;
                }
              } else {
                return project;
              }
            }),
          });

          if (newProjectList.length > 0) {
            if (this.state.mounted) {
              this.onClickProjectItemLabel(newProjectList[0]);
              this.setState({
                projectList: newProjectList,
              });
            }
          }
        }
      }
    });
  };

  getApproveRejectDataFrSelectedObject = (project) => {
    const addApproveRejectData = getPieChartDetails(
      project,
      this.state.selectedDate.year,
      this.state.selectedDate.week,
      this.props.loggedUser
    );

    addApproveRejectData
      .then((response) => {
        let approveRejectDetails = response?.data?.approveRejectDetails;
        return approveRejectDetails;
      })
      .catch((error) => {
        // console.log(err)
        let message = getErrorMessage(error);
        this.setState({
          loading: false,
          error: {
            status: true,
            message: message,
          },
        });
      });

    return addApproveRejectData;
  };

  readApprovedRejectData = (selectProject) => {
    let ApprovedRejectDetails =
      this.getApproveRejectDataFrSelectedObject(selectProject);
    Promise.resolve(ApprovedRejectDetails).then((obj) => {
      let updateProjectObj = obj.project;
      this.setSelectedObjectToProjectList(updateProjectObj);
    });
  };
  updateProjectObj = () => {
    let updateProjectObj;

    getTeamMemberDataMethod(
      envConfig.services.checkinBffServiceWebApproval,
      envConfig.services.projectTeamMemberDetails,
      "?project-id=",
      this.state.selectedProject.selectProject.gteProjectId,
      "&year=",
      this.state.selectedDate.year,
      "&week-no=",
      this.state.selectedDate.week,
      "&user-name=",
      this.props.loggedUser.email
    ).then((res) => {
      let data = res?.data;

      let newtotalTimeNmembersList =
        this.calTotalTimeNupdateTeammemberObject(data);

      let project = this.state.selectedProject.selectProject;

      let totalTimeN =
        newtotalTimeNmembersList.totalTimeOfPendingApproval +
        newtotalTimeNmembersList.totalTimeOfPendingConfirmation;

      updateProjectObj = {
        ...project,
        totalTime: Math.round((totalTimeN + Number.EPSILON) * 100) / 100,
        totalTimeOfPendingConfirmation:
          newtotalTimeNmembersList.totalTimeOfPendingConfirmation,
        teamMembers: newtotalTimeNmembersList.teamMembersList.sort((a, b) =>
          a.name > b.name ? 1 : -1
        ),
        totalTimeOfApproved: 0,
        totalTimeOfReject: 0,
        pendingConfirmations:
          newtotalTimeNmembersList.pendingConfirmationList.sort((a, b) =>
            a.name > b.name ? 1 : -1
          ),
      };

      if (updateProjectObj.hasOwnProperty("teamMembers")) {
        this.readApprovedRejectData(updateProjectObj);
      }
    });
  };

  setSelectedObjectToProjectList = (updateProjectObj) => {
    let selectedProject = this.state.selectedProject;
    let updateProjectList = this.state.projectList?.map((projectObj) => {
      if (projectObj.gteProjectId === updateProjectObj.gteProjectId) {
        return updateProjectObj;
      }
      return projectObj;
    });
    this.setState({
      selectedProject: {
        ...selectedProject,
        selectProject: updateProjectObj,
      },
      model: false,
      loading: false,
      projectList: [...updateProjectList].sort((a, b) =>
        a.totalTime < b.totalTime
          ? 1
          : a.totalTime === b.totalTime
          ? a.projectName > b.projectName
            ? 1
            : -1
          : -1
      ),
      // projectList: this.state.projectList?.map((projectObj) => {
      //   if (projectObj.gteProjectId === updateProjectObj.gteProjectId) {
      //     return updateProjectObj;
      //   }
      //   return projectObj;
      // }),
    });

    this.updateTotalTimeForDay(updateProjectObj);
  };
  updateTotalTimeForDay = (updateProjectObj) => {
    // console.log(updateProjectObj)
    // console.log("selectedProject ", this.state.selectedProject)
    if (this.state.selectedProject.type === "project") {
      this.calculateTotalTimeForDay("project", updateProjectObj);
    }
    if (this.state.selectedProject.type === "teamMember") {
      let teamMember;
      updateProjectObj?.teamMembers?.map((member) => {
        if (member.gteEmployeeNo === this.state.selectedProject.gteEmployeeNo) {
          teamMember = member;
        }
      });
      this.calculateTotalTimeForDay("teamMember", teamMember);
    }
    if (this.state.selectedProject.type === "task") {
      let taskObj;
      updateProjectObj?.teamMembers?.map((member) => {
        if (member.gteEmployeeNo === this.state.selectedProject.gteEmployeeNo) {
          member?.taskData?.map((task) => {
            if (task?.taskId === this.state.selectedProject.taskId) {
              taskObj = task;
            }
          });
        }
      });
      this.calculateTotalTimeForDay("task", taskObj);
    }
  };
  mergePendingApprovalAndPending_Confirmation_List = (
    pendingApprovalList,
    pendingConfirmationList
  ) => {
    if (pendingConfirmationList.length > 0) {
      pendingConfirmationList.map((teamMember) => {
        if (
          pendingApprovalList.some(
            (pendingApprovalList) =>
              pendingApprovalList.gteEmployeeNo === teamMember.gteEmployeeNo
          )
        ) {
          pendingApprovalList = pendingApprovalList?.map((member) => {
            if (member.gteEmployeeNo === teamMember.gteEmployeeNo) {
              let taskDataOfConfirm = teamMember?.taskData;
              let taskDataApproval = member?.taskData;
              taskDataOfConfirm?.map((task) => {
                if (
                  taskDataApproval?.some(
                    (taskDataApproval) =>
                      taskDataApproval.taskId === task.taskId
                  )
                ) {
                  //################################################################
                } else {
                  taskDataApproval = [
                    ...taskDataApproval,
                    { ...task, isDisable: true },
                  ];
                }
              });

              return {
                ...member,
                taskData: taskDataApproval.sort((a, b) =>
                  a.taskName > b.taskName ? 1 : -1
                ),
              };
            } else {
              return member;
            }
          });
        } else {
          pendingApprovalList.push({ ...teamMember, isDisable: true });
        }
      });

      return pendingApprovalList;
    }
  };

  /*
   * set "isSelected" value -> required for checkboxes
   * calculate "total time Of Project"
   * response?data -> "pendingApproval" array list take as teamMember list
   */
  calTotalTimeNupdateTeammemberObject = (data) => {
    let totalTimeOfPendingApproval = 0.0;
    let totalTimeOfPendingConfirmation = 0;

    let teamMemberList = data?.pendingApproval?.map((teamMember) => {
      let anySelectedEntry = 0;

      let taskDataList = teamMember?.taskData?.map((task) => {
        anySelectedEntry = 0;
        let anySelectedEntryTask = 0;
        let timeDataList = task?.timeData?.map((timeDataObj) => {
          anySelectedEntryTask = 0;
          let timeEntryDataList = timeDataObj?.timeEntryData?.map(
            (timeEntry) => {
              totalTimeOfPendingApproval =
                totalTimeOfPendingApproval + timeEntry.time;

              timeEntry.isSelected === 1 && (anySelectedEntry = 1);
              timeEntry.isSelected === 1 && (anySelectedEntryTask = 1);
              return { ...timeEntry, approvedType: "N" };
            }
          );

          return {
            ...timeDataObj,
            timeEntryData: timeEntryDataList,
            isSelected: anySelectedEntryTask,
          };
        });
        return {
          ...task,
          timeData: timeDataList,
          isChanged: false,
          isDisable: false,
          isSelected: anySelectedEntryTask,
        };
      });

      return {
        ...teamMember,
        isExpand: false,
        isSelected: anySelectedEntry,
        isChanged: false,
        taskData: taskDataList.sort((a, b) =>
          a.taskName > b.taskName ? 1 : -1
        ),
        isDisable: false,
      };
    });

    let pendingConfirmationList = data?.pendingConfirmation?.map(
      (teamMember) => {
        // "taskId": 1000,"taskName": "Development", "Billable": "Y","timeData":[]
        let taskDataList = teamMember?.taskData?.map((task) => {
          let timeDataList = task?.timeData?.map((timeDataObj) => {
            let timeEntryDataList = timeDataObj?.timeEntryData?.map(
              (timeEntry) => {
                totalTimeOfPendingConfirmation =
                  totalTimeOfPendingConfirmation + timeEntry.time;
                return { ...timeEntry, approvedType: "C", isDisable: true };
                // approvedType -> which type ->initialy -> "N" [not get for any type of reject or approved] -> "R"-> when click "Reject Selection" or "A"-> when click "Approve Selection"
              }
            );

            return {
              ...timeDataObj,
              timeEntryData: timeEntryDataList,
              isSelected: 1,
              isDisable: true,
            };
          });
          return {
            ...task,
            timeData: timeDataList,
            isSelected: 1,
            isChanged: false,
            isDisable: true,
          };
        });

        return {
          ...teamMember,
          isExpand: false,
          isSelected: 1,
          isChanged: false,
          taskData: taskDataList.sort((a, b) =>
            a.taskName > b.taskName ? 1 : -1
          ),
          isDisable: true,
        };
      }
    );

    if (pendingConfirmationList.length > 0) {
      let newTeamMemberList =
        this.mergePendingApprovalAndPending_Confirmation_List(
          teamMemberList,
          pendingConfirmationList
        );
      return {
        teamMembersList: newTeamMemberList,
        totalTimeOfPendingApproval:
          Math.round((totalTimeOfPendingApproval + Number.EPSILON) * 100) / 100,
        totalTimeOfApproved: 0,
        totalTimeOfReject: 0,
        pendingConfirmationList: pendingConfirmationList,
        totalTimeOfPendingConfirmation:
          Math.round((totalTimeOfPendingConfirmation + Number.EPSILON) * 100) /
          100,
      };
    } else {
      return {
        teamMembersList: teamMemberList,
        totalTimeOfPendingApproval:
          Math.round((totalTimeOfPendingApproval + Number.EPSILON) * 100) / 100,
        totalTimeOfApproved: 0,
        totalTimeOfReject: 0,
        pendingConfirmationList: pendingConfirmationList,
        totalTimeOfPendingConfirmation:
          Math.round((totalTimeOfPendingConfirmation + Number.EPSILON) * 100) /
          100,
      };
    }
  };

  /**
   * onClickProjectItemLabel,onClickMemberItemLabel,onClickTaskItemLabel
   * Update state of selectedProject, item
   *  ->required those data for -> Rendering timeApproveMainPage
   */

  onClickProjectItemRow = (project) => {
    this.setState({
      item: project.gteProjectId,
    });
  };
  /**
   * call -> every click on project item of leftpanel project list
   *  special note ->except above mention criteria, this method call after initial load [end of approach2 method]
   *            -> reason -> to update state of "selectedproject" -> highligted 1st project item and load required other data (ex fetch chart data, teamMemberCard data)
   */
  onClickProjectItemLabel = (project) => {
    this.resetDaysCheckbox();

    this.calculateTotalTimeForDay("project", project);

    this.setState({
      item: project.gteProjectId,
      selectedProject: {
        selectProject: project,
        type: "project",

        gteProjectId: project.gteProjectId,
        gteEmployeeNo: 0,
        taskId: 0,
      },
    });
    if (this.state.selectedProject.type !== "initial") {
      this.getBarChartData();
      this.getPieChartData();
    }
  };

  // onClickMemberItemRow = (project, teamMember) => {

  //   this.resetDaysCheckbox();

  //   this.calculateTotalTimeForDay("teamMember", teamMember);

  //   this.setState({
  //     item: project.gteProjectId + "-" + teamMember.gteEmployeeNo,
  //     selectedProject: {
  //       selectProject: project,
  //       type: "teamMember",

  //       gteProjectId: project.gteProjectId,
  //       gteEmployeeNo: 0,
  //       taskId: 0,
  //     },
  //   });
  // };

  onClickMemberItemLabel = (project, teamMember) => {
    this.resetDaysCheckbox();

    this.calculateTotalTimeForDay("teamMember", teamMember);

    this.setState({
      item: project.gteProjectId + "-" + teamMember.gteEmployeeNo,

      selectedProject: {
        type: "teamMember",
        selectProject: project,
        gteProjectId: project.gteProjectId,
        gteEmployeeNo: teamMember.gteEmployeeNo,
        taskId: 0,
      },
    });
  };

  onClickTaskItemRow = (project, teamMember, task) => {
    this.setState({
      item:
        project.gteProjectId +
        "-" +
        teamMember.gteEmployeeNo +
        "-" +
        task.taskId,
    });
  };
  onClickTaskItemLabel = (project, teamMember, taskData) => {
    // this.onClickTaskItemLabel(project, teamMember, task)
    this.resetDaysCheckbox();

    this.calculateTotalTimeForDay("task", taskData); //project's teammember's taskdata list -> taskData obj

    this.setState({
      item:
        project.gteProjectId +
        "-" +
        teamMember.gteEmployeeNo +
        "-" +
        taskData.taskId,
      selectedProject: {
        selectProject: project,
        type: "task",

        gteProjectId: project.gteProjectId,
        gteEmployeeNo: teamMember.gteEmployeeNo,
        taskId: taskData.taskId,
      },
    });
  };

  /**
   * calculateTotalTimeForDay()-> use to calculate total time and set this value to this.state.selectedDate.days[array] -> day [obj] ->total Time
   * -> required -> to set total time of each day -> when rendering day card
   * ->Within this method
   *    -> calculateTotalTimeForDay_Type_Project()-> for project item selection
   *    -> calculateTotalTimeForDay_Type_TeamMember / _Task() -> for teamMember/ task item selection
   */

  calculateTotalTimeForDay = (type, item) => {
    if (type === "project") {
      this.calculateTotalTimeForDay_Type_Project(item);
    }
    if (type === "teamMember") {
      this.calculateTotalTimeForDay_Type_TeamMember(item);
    }
    if (type === "task") {
      this.calculateTotalTimeForDay_Type_Task(item); //project's teammember's taskdata list -> taskData obj
    }
  };

  calculateTotalTimeForDay_Type_Project = (item) => {
    let days = this.state.selectedDate?.days?.map((day) => {
      let totalTime = 0;

      item?.teamMembers?.map((member) => {
        member?.taskData?.map((task) => {
          task?.timeData?.map((timeDataObj) => {
            if (timeDataObj.date === day.date) {
              timeDataObj.timeEntryData?.map((timeEntry) => {
                if (timeEntry.approvedType === "N") {
                  totalTime = totalTime + timeEntry.time;
                }
                // totalTime = totalTime + time.time;
              });
            }
          });
        });
      });
      return {
        ...day,
        timeTotal: Math.round((totalTime + Number.EPSILON) * 100) / 100,
      };
    });

    let selectedDate = this.state.selectedDate;
    this.setState({
      selectedDate: { ...selectedDate, days: days },
    });
  };
  calculateTotalTimeForDay_Type_TeamMember = (item) => {
    let days = this.state.selectedDate?.days?.map((day) => {
      let totalTime = 0;

      item?.taskData?.map((task) => {
        task?.timeData?.map((timeDataObj) => {
          if (timeDataObj.date === day.date) {
            timeDataObj.timeEntryData?.map((timeEntry) => {
              if (timeEntry.approvedType === "N") {
                totalTime = totalTime + timeEntry.time;
              }
            });
          }
        });
      });
      return {
        ...day,
        timeTotal: Math.round((totalTime + Number.EPSILON) * 100) / 100,
      };
    });

    let selectedDate = this.state.selectedDate;
    this.setState({
      selectedDate: { ...selectedDate, days: days },
    });
  };
  calculateTotalTimeForDay_Type_Task = (item) => {
    let days = this.state.selectedDate?.days?.map((day) => {
      let totalTime = 0;
      item?.timeData?.map((timeDataObj) => {
        if (timeDataObj.date === day.date) {
          timeDataObj.timeEntryData?.map((timeEntry) => {
            if (timeEntry.approvedType === "N") {
              totalTime = totalTime + timeEntry.time;
            }
          });
        }
      });

      return {
        ...day,
        timeTotal: Math.round((totalTime + Number.EPSILON) * 100) / 100,
      };
    });

    let selectedDate = this.state.selectedDate;
    this.setState({
      selectedDate: { ...selectedDate, days: days },
    });
  };

  /**
   * pastWeek, nextWeek -> Update state of selectedDate -> When -> click arrow keys -> for change week
   */

  pastWeek = () => {
    let reqDate = this.state.selectedDate.firstDayofCurrentWeek;

    let dateOfLastMondy = moment(reqDate)
      .subtract(7, "days")
      .format("YYYY-MM-DD");

    this.setState({
      selectedDate: {
        firstDayofCurrentWeek: dateOfLastMondy,
        year: moment(dateOfLastMondy).startOf("isoweek").format("YYYY"),
        week: moment(dateOfLastMondy).startOf("isoweek").format("WW"),

        dateOfFirstDayofCurrentWeek: moment(dateOfLastMondy)
          .startOf("isoweek")
          .format("D MMMM"),
        dateOfLastDayofCurrentWeek: moment(dateOfLastMondy)
          .endOf("isoweek")
          .format("D MMMM"),
        days: this.createListofDays(dateOfLastMondy),
      },
    });
  };

  nextWeek = () => {
    let reqDate = this.state.selectedDate.firstDayofCurrentWeek;

    let dateOfNextMonday = moment(reqDate).add(7, "days").format("YYYY-MM-DD");

    this.setState({
      selectedDate: {
        firstDayofCurrentWeek: dateOfNextMonday,
        year: moment(dateOfNextMonday).startOf("isoweek").format("YYYY"),
        week: moment(dateOfNextMonday).startOf("isoweek").format("WW"),

        dateOfFirstDayofCurrentWeek: moment(dateOfNextMonday)
          .startOf("isoweek")
          .format("D MMMM"),
        dateOfLastDayofCurrentWeek: moment(dateOfNextMonday)
          .endOf("isoweek")
          .format("D MMMM"),
        days: this.createListofDays(dateOfNextMonday),
      },
    });
  };
  createListofDays(firstDayofCurrentWeek) {
    let days = [];
    for (let i = 0; i < 7; i++) {
      days.push({
        day: i,
        date: moment(firstDayofCurrentWeek).add(i, "days").format("YYYY-MM-DD"),
        timeTotal: 0,
        isSelected: 1,
      });
    }
    return days;
  }

  resetDaysCheckbox = () => {
    let selectedDate = this.state.selectedDate;
    selectedDate.days = this.state.selectedDate.days?.map((day) => {
      if (day.isSelected === 0) {
        return { ...day, isSelected: 1 };
      }
      return day;
    });

    this.setState({
      selectedDate: selectedDate,
    });
  };
  /**
   * expandProject() -> expand TeamMember List of selected Project Item
   * expandTeamMember() -> expand Task list of selected TeamMember Item
   */
  expandProject(isExpand, gteProjectId) {
    this.setState({
      projectList: this.state.projectList.map((project) => {
        if (project.gteProjectId === gteProjectId) {
          return { ...project, isExpand: !isExpand };
        }
        return project;
      }),
    });
  }

  expandTeamMember = (isExpand, gteProjectId, member) => {
    this.setState({
      projectList: this.state.projectList.map((project) => {
        if (project.gteProjectId === gteProjectId) {
          project.teamMembers = project.teamMembers?.map((teamMember) => {
            if (teamMember.gteEmployeeNo === member.gteEmployeeNo) {
              return { ...teamMember, isExpand: !isExpand };
            }
            return teamMember;
          });
        }
        return project;
      }),
    });
  };

  /**
   * handleCheckboxSelectionOfDayCard()-> handle checkbox selection of each DayCard element
   * -> 1st Call start from ->
   *  onChange={(event)=> this.props.handleCheckboxSelectionOfDayCard((event.target.checked?1:0),this.props.day) }
   * of formCheckInput in dayCard.jsx
   *
   * its call and passed data to handleCheckboxSelectionOfDayCard in <DayCard> Element in timeApproverMainPage.jsx
   * from <DayCard element> - call to handleCheckboxSelectionOfDayCard of <TimeApproveMainPage> Element of mainMenu.jsx
   * from it call to below mention method
   */

  /**
   *
   * @param {*} value  -> pass checkbox event value
   * @param {*} day  -> perticular day of selected checkbox of DayCard
   *
   *--------------------------------------------------------------------------->>
   */
  handleCheckboxSelectionOfDayCard = (value, day) => {
    this.updateStateofSelectedProject_forDayCard(value, day);
    this.updateStateofSelectedDate(value, day);
  };

  /**
   *   updateStateofSelectedProject_forDayCard ->
   * @param {*} value  - chekbox selection value of day
   * @param {*} day - selected day
   *
   * within this method update the state of Selected project, if selection type - project , teamMember
   *  within if block -> update selectedProject state, and call updateUserSelection() method
   */

  updateStateofSelectedProject_forDayCard = (value, day) => {
    // console.log("value " + value + "-day- ", day);
    let selectedProject = this.state.selectedProject;
    let selectProject = selectedProject.selectProject;
    let teamMembers;
    // let data;
    let gteProjectId = 0;
    let gteEmployeeNo = 0;
    let taskId = 0;
    let selectionChange = [];
    if (this.state.selectedProject.type === "project") {
      teamMembers = selectProject.teamMembers?.map((teamMember) => {
        let taskData = teamMember?.taskData?.map((taskDataObj) => {
          let timeData = taskDataObj?.timeData?.map((timeDataObj) => {
            if (timeDataObj.date === day.date) {
              // console.log("value N day.date " + value + " - " + day.date);
              let timeEntryData = timeDataObj?.timeEntryData?.map(
                (timeEntryObj) => {
                  // let selectionChangeValue = {
                  //   selectionChange: [
                  //     {
                  //       timeRecord: timeEntryObj.timeRecordId,
                  //       timeEntry: [timeEntryObj.timeEntryId],
                  //     },
                  //   ],
                  // };
                  selectionChange.push({
                    timeRecord: timeEntryObj.timeRecordId,
                    timeEntry: [timeEntryObj.timeEntryId],
                  });
                  // this.updateUserSelection(
                  //   selectProject.gteProjectId,
                  //   this.state.selectedDate.year,
                  //   this.state.selectedDate.week,
                  //   value,
                  //   selectionChangeValue
                  // );
                  return { ...timeEntryObj, isSelected: value };
                }
              );

              return {
                ...timeDataObj,
                isSelected: value,
                timeEntryData: timeEntryData,
              };
            } else {
              return timeDataObj;
            }
          });
          return { ...taskDataObj, timeData: timeData };
        });
        return { ...teamMember, taskData: taskData };
      });
      // this.updateStateofProjectList()
    }
    if (this.state.selectedProject.type === "teamMember") {
      teamMembers = selectProject.teamMembers?.map((teamMember) => {
        if (
          teamMember.gteEmployeeNo === this.state.selectedProject.gteEmployeeNo
          // this.state.selectedProject.data.gteEmployeeNo
        ) {
          let taskData = teamMember?.taskData?.map((taskDataObj) => {
            let timeData = taskDataObj?.timeData?.map((timeDataObj) => {
              if (timeDataObj.date === day.date) {
                let timeEntryData = timeDataObj?.timeEntryData?.map(
                  (timeEntryObj) => {
                    // let selectionChangeValue = {
                    //   selectionChange: [
                    //     {
                    //       timeRecord: timeEntryObj.timeRecordId,
                    //       timeEntry: [timeEntryObj.timeEntryId],
                    //     },
                    //   ],
                    // };
                    // this.updateUserSelection(
                    //   selectProject.gteProjectId,
                    //   this.state.selectedDate.year,
                    //   this.state.selectedDate.week,
                    //   value,
                    //   selectionChangeValue
                    // );
                    selectionChange.push({
                      timeRecord: timeEntryObj.timeRecordId,
                      timeEntry: [timeEntryObj.timeEntryId],
                    });
                    return { ...timeEntryObj, isSelected: value };
                  }
                );

                return {
                  ...timeDataObj,
                  isSelected: value,
                  timeEntryData: timeEntryData,
                };
              } else {
                return timeDataObj;
              }
            });
            return { ...taskDataObj, timeData: timeData };
          });
          gteProjectId = this.state.selectedProject.selectProject.gteProjectId;
          gteEmployeeNo = teamMember.gteEmployeeNo;
          taskId = 0;
          return { ...teamMember, taskData: taskData };
        } else {
          return teamMember;
        }
      });
    }

    selectProject = { ...selectProject, teamMembers: teamMembers };
    if (this.state.selectedProject.type === "project") {
      gteProjectId = selectProject.gteProjectId;
      gteEmployeeNo = 0;
      taskId = 0;
    }

    let projectList = this.state.projectList?.map((projectObj) => {
      if (projectObj.gteProjectId === selectProject.gteProjectId) {
        return {
          ...projectObj,
          teamMembers: teamMembers,
        };
      }
      return projectObj;
    });
    let selectionChangeValue = {
      selectionChange: selectionChange,
    };
    this.updateUserSelection(
      selectProject.gteProjectId,
      this.state.selectedDate.year,
      this.state.selectedDate.week,
      value,
      selectionChangeValue
    );

    this.setState({
      selectedProject: {
        ...selectedProject,

        selectProject: selectProject,
        gteProjectId: gteProjectId,
        gteEmployeeNo: gteEmployeeNo,
        taskId: taskId,
      },
      projectList: projectList,
    });
  };

  updateStateofSelectedDate = (value, day) => {
    let selectedDate = this.state.selectedDate;
    let days = this.state.selectedDate.days?.map((dayObj) =>
      dayObj.date === day.date ? { ...dayObj, isSelected: value } : dayObj
    );
    this.setState({
      selectedDate: {
        ...selectedDate,
        days: days,
      },
    });
    // }
  };

  handleApproveOrRejectSelectionButtonFuntion = (type) => {
    if (this.state.selectedProject.type !== "initial") {
      this.setState({
        loading: true,
      });
      let totalTime = 0;
      let selectedProject = this.state.selectedProject;
      let selectProject = selectedProject.selectProject;
      let teamMembers;
      /**
       * @param {*}  approvedTimeMap = new Map();
       * ->{use Map algorithem to filter data (map has unique keys)}
       * ---> use Map's key & value  -> to get approvedTimeList records without adding duplicate data
       *  KEY -> timeEntry.timeRecordId , VALUE -> timeEntry.timeEntryId 's under unique timeEntry.timeRecordId
       *
       */
      let approvedTimeMap = new Map();
      /**
       * @param {*}  approvedTimeList = []; -> collect data as required for payload
       *  create object as required for payload and add created object to approvedTimeList
       */
      let approvedTimeList = [];

      teamMembers = selectProject.teamMembers?.map((teamMember) => {
        let taskData = teamMember?.taskData?.map((taskDataObj) => {
          let timeData = taskDataObj?.timeData?.map((timeDataObj) => {
            let timeEntryData = timeDataObj?.timeEntryData?.map((timeEntry) => {
              if (
                timeEntry.approvedType === "N" &&
                timeEntry.isSelected === 1
              ) {
                if (approvedTimeMap.has(timeEntry.timeRecordId)) {
                  /**
                   * if approvedTimeMap has timeEntry.timeRecordId as Key ->
                   *         means ->  already added data set under this key.
                   * So, 1. capture the array of timeEntry by using timeEntryArray and add new timeEntry.timeEntryId to this list
                   * and update map and approvedTimeList array
                   *    2. Also add data set to approvedTimeList Array
                   */
                  let timeEntryArray = approvedTimeMap.get(
                    timeEntry.timeRecordId
                  );
                  timeEntryArray = [...timeEntryArray, timeEntry.timeEntryId];
                  approvedTimeMap.set(timeEntry.timeRecordId, timeEntryArray);

                  approvedTimeList = approvedTimeList?.map((obj) => {
                    if (obj.timeRecord === timeEntry.timeRecordId) {
                      return {
                        timeRecord: timeEntry.timeRecordId,
                        timeEntry: timeEntryArray,
                      };
                    } else {
                      return obj;
                    }
                  });
                } else {
                  /**
                   * if approvedTimeMap has not timeEntry.timeRecordId as Key ->
                   *           1. it take as new data set and add key value pair to approvedTimeMap
                   *    2. Also add data set to approvedTimeList Array
                   */
                  approvedTimeMap.set(timeEntry.timeRecordId, [
                    timeEntry.timeEntryId,
                  ]);
                  approvedTimeList = [
                    ...approvedTimeList,
                    {
                      timeRecord: timeEntry.timeRecordId,
                      timeEntry: [timeEntry.timeEntryId],
                    },
                  ];
                }

                totalTime = totalTime + timeEntry.time;
                return { ...timeEntry, approvedType: type };
              } else {
                return timeEntry;
              }
            });

            return {
              ...timeDataObj,
              timeEntryData: timeEntryData,
            };
          });
          return { ...taskDataObj, timeData: timeData };
        });
        return { ...teamMember, taskData: taskData };
      });

      if (approvedTimeList.length > 0) {
        let value = {
          userName: this.props.loggedUser.email,
          identification: "W/A",
          approvedTime: approvedTimeList,
        };
        this.approveOrRejectDataSave(type, value);
      } else {
        this.setState({
          loading: false,
        });
      }
    }
  };
  approveOrRejectDataSave = (type, value) => {
    this.setState({
      AorR_response: { type: type, value: value },
    });

    patchMethod(
      envConfig.services.checkinBffServiceWebApproval,
      this.state.selectedProject.selectProject.gteProjectId,
      this.state.selectedDate.year,
      this.state.selectedDate.week,
      type,
      value
    )
      .then((response) => {
        this.updateProjectObj(this.state.selectedProject.selectProject);
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          loading: false,
          error: {
            status: true,
            message: message,
          },
        });
      });
  };
  handleConfirmApprovedTimeforBillingButton = () => {
    // this.getBarChartData();
    // this.getPieChartData();
    if (this.state.selectedProject.type !== "initial") {
      if (this.state.setPieChartData && this.state.setBarChartData) {
        this.setState({
          model: true,
        });
      }
    }

    // const requestId = `REQUEST-${Date.now()}`;
    // this.lastRequestId = requestId;

    // setTimeout(() => {
    //   if (this.lastRequestId !== requestId) {
    //     return;
    //   } else {

    //   }
    // }, 3000);
  };

  handleCheckBoxSelectionEventOfItemCard = (value, item, day) => {
    let selectedProject = this.state.selectedProject;
    let selectProject = selectedProject.selectProject;
    let teamMembers;
    let selectionChange = [];
    if (this.state.selectedProject.type === "project") {
      teamMembers = selectProject.teamMembers?.map((teamMember) => {
        let anySelectedEntry = 0;

        if (teamMember.gteEmployeeNo === item.gteEmployeeNo) {
          anySelectedEntry = 0;
          let anySelectedEntryTask = 0;
          let taskData = teamMember?.taskData?.map((taskDataObj) => {
            let timeData = taskDataObj?.timeData?.map((timeDataObj) => {
              if (timeDataObj.date === day.date) {
                let timeEntryData = timeDataObj?.timeEntryData?.map(
                  (timeEntryObj) => {
                    // let selectionChangeValue = {
                    //   selectionChange: [
                    //     {
                    //       timeRecord: timeEntryObj.timeRecordId,
                    //       timeEntry: [timeEntryObj.timeEntryId],
                    //     },
                    //   ],
                    // };
                    // this.updateUserSelection(
                    //   selectProject.gteProjectId,
                    //   this.state.selectedDate.year,
                    //   this.state.selectedDate.week,
                    //   value,
                    //   selectionChangeValue
                    // );

                    selectionChange.push({
                      timeRecord: timeEntryObj.timeRecordId,
                      timeEntry: [timeEntryObj.timeEntryId],
                    });

                    // value === 1  ? (isSelectedFalseFrTask = 1)  : (isSelectedTrueFrTask = 0);
                    value === 1 && (anySelectedEntry = 1);
                    value === 1 && (anySelectedEntryTask = 1);
                    return { ...timeEntryObj, isSelected: value };
                  }
                );

                return {
                  ...timeDataObj,
                  isSelected: anySelectedEntryTask,
                  timeEntryData: timeEntryData,
                };
              } else {
                timeDataObj?.timeEntryData?.map((timeEntryObj) => {
                  timeEntryObj.isSelected === 1 && (anySelectedEntryTask = 1);
                });

                return timeDataObj;
              }
            });

            return {
              ...taskDataObj,
              timeData: timeData,
              isSelected: anySelectedEntryTask,
            };
          });

          return {
            ...teamMember,
            taskData: taskData,
            isSelected: anySelectedEntry,
          };
        } else {
          return teamMember;
        }
      });
    }
    if (this.state.selectedProject.type === "teamMember") {
      teamMembers = selectProject.teamMembers?.map((teamMember) => {
        let anySelectedEntry = 0;
        if (
          teamMember.gteEmployeeNo === this.state.selectedProject.gteEmployeeNo
        ) {
          anySelectedEntry = 0;
          let anySelectedEntryTask = 0;
          let taskData = teamMember?.taskData?.map((taskDataObj) => {
            anySelectedEntryTask = 0;
            if (taskDataObj.taskId === item.taskId) {
              let timeData = taskDataObj?.timeData?.map((timeDataObj) => {
                if (timeDataObj.date === day.date) {
                  let timeEntryData = timeDataObj?.timeEntryData?.map(
                    (timeEntryObj) => {
                      // let selectionChangeValue = {
                      //   selectionChange: [
                      //     {
                      //       timeRecord: timeEntryObj.timeRecordId,
                      //       timeEntry: [timeEntryObj.timeEntryId],
                      //     },
                      //   ],
                      // };
                      // this.updateUserSelection(
                      //   selectProject.gteProjectId,
                      //   this.state.selectedDate.year,
                      //   this.state.selectedDate.week,
                      //   value,
                      //   selectionChangeValue
                      // );

                      selectionChange.push({
                        timeRecord: timeEntryObj.timeRecordId,
                        timeEntry: [timeEntryObj.timeEntryId],
                      });

                      value === 1 && (anySelectedEntry = 1);
                      value === 1 && (anySelectedEntryTask = 1);
                      return { ...timeEntryObj, isSelected: value };
                    }
                  );

                  return {
                    ...timeDataObj,
                    isSelected: anySelectedEntryTask,
                    timeEntryData: timeEntryData,
                  };
                } else {
                  timeDataObj?.timeEntryData?.map((timeEntryObj) => {
                    timeEntryObj.isSelected === 1 && (anySelectedEntry = 1);
                    timeEntryObj.isSelected === 1 && (anySelectedEntryTask = 1);
                  });
                  return timeDataObj;
                }
              });
              return {
                ...taskDataObj,
                timeData: timeData,
                isSelected: anySelectedEntryTask,
              };
            } else {
              taskDataObj?.timeData?.map((timeDataObj) => {
                timeDataObj?.timeEntryData?.map((timeEntryObj) => {
                  timeEntryObj.isSelected === 1 && (anySelectedEntry = 1);
                  timeEntryObj.isSelected === 1 && (anySelectedEntryTask = 1);
                });
              });
              return {
                ...taskDataObj,
                isSelected: anySelectedEntryTask,
              };
            }
          });

          return {
            ...teamMember,
            taskData: taskData,
            isSelected: anySelectedEntry,
          };
        } else {
          return teamMember;
        }
      });
    }
    if (this.state.selectedProject.type === "task") {
      let anySelectedEntry;
      teamMembers = selectProject.teamMembers?.map((teamMember) => {
        anySelectedEntry = 0;
        if (
          teamMember.gteEmployeeNo === this.state.selectedProject.gteEmployeeNo
        ) {
          let anySelectedEntryTask = 0;
          let taskData = teamMember?.taskData?.map((taskDataObj) => {
            anySelectedEntryTask = 0;
            if (taskDataObj.taskId === this.state.selectedProject.taskId) {
              let timeData = taskDataObj?.timeData?.map((timeDataObj) => {
                if (timeDataObj.date === day.date) {
                  let timeEntryData = timeDataObj?.timeEntryData?.map(
                    (timeEntryObj) => {
                      if (timeEntryObj.timeEntryId === item.timeEntryId) {
                        // let selectionChangeValue = {
                        //   selectionChange: [
                        //     {
                        //       timeRecord: timeEntryObj.timeRecordId,
                        //       timeEntry: [timeEntryObj.timeEntryId],
                        //     },
                        //   ],
                        // };
                        // this.updateUserSelection(
                        //   selectProject.gteProjectId,
                        //   this.state.selectedDate.year,
                        //   this.state.selectedDate.week,
                        //   value,
                        //   selectionChangeValue
                        // );

                        selectionChange.push({
                          timeRecord: timeEntryObj.timeRecordId,
                          timeEntry: [timeEntryObj.timeEntryId],
                        });

                        value === 1 && (anySelectedEntry = 1);
                        value === 1 && (anySelectedEntryTask = 1);
                        return { ...timeEntryObj, isSelected: value };
                      } else {
                        timeEntryObj.isSelected === 1 && (anySelectedEntry = 1);
                        return timeEntryObj;
                      }
                    }
                  );

                  return {
                    ...timeDataObj,

                    timeEntryData: timeEntryData,
                  };
                } else {
                  timeDataObj?.timeEntryData?.map((timeEntryObj) => {
                    timeEntryObj.isSelected === 1 && (anySelectedEntry = 1);
                    timeEntryObj.isSelected === 1 && (anySelectedEntryTask = 1);
                  });
                  return timeDataObj;
                }
              });

              return {
                ...taskDataObj,
                timeData: timeData,
                isSelected: anySelectedEntryTask,
              };
            } else {
              taskDataObj?.timeData?.map((timeDataObj) => {
                timeDataObj?.timeEntryData?.map((timeEntryObj) => {
                  timeEntryObj.isSelected === 1 && (anySelectedEntry = 1);
                  timeEntryObj.isSelected === 1 && (anySelectedEntryTask = 1);
                });
              });

              return {
                ...taskDataObj,
                isSelected: anySelectedEntryTask,
              };
            }
          });

          return {
            ...teamMember,
            taskData: taskData,
            isSelected: anySelectedEntry,
          };
        } else {
          return teamMember;
        }
      });
    }

    selectProject = { ...selectProject, teamMembers: teamMembers };
    if (this.state.selectedProject.type === "project") {
      // data = selectProject;
      // gteProjectId = selectProject.gteProjectId;
      // gteEmployeeNo = 0;
      // taskId = 0;
    }

    let projectList = this.state.projectList?.map((projectObj) => {
      if (projectObj.gteProjectId === selectProject.gteProjectId) {
        return {
          ...projectObj,
          teamMembers: teamMembers,
        };
      }
      return projectObj;
    });

    let selectionChangeValue = {
      selectionChange: selectionChange,
    };
    this.updateUserSelection(
      selectProject.gteProjectId,
      this.state.selectedDate.year,
      this.state.selectedDate.week,
      value,
      selectionChangeValue
    );

    this.setState({
      selectedProject: {
        ...selectedProject,

        selectProject: selectProject,
      },
      projectList: projectList,
    });
  };

  /**
   * Update selection of checkboxes of timecards ->
   * -> call in every changes of selection of timecard in projectlevel, teamMember level and task level
   */
  updateUserSelection = (gteProjectId, year, week, state, value) => {
    console.log("value: ", value, "state ", state);
    new Promise((resolve) => {
      resolve(
        patchMethod(
          envConfig.services.checkinBffServiceWebApproval +
            envConfig.services.userSelection,
          parseInt(gteProjectId),
          year,
          week,
          state,
          value
        )
          .then((response) => {})
          .catch((error) => {
            let message = getErrorMessage(error);
            this.setState({
              loading: false,
              error: {
                status: true,
                message: message,
              },
            });
          })
      );
    });
  };

  handleCheckBoxChangeEventOfTeamMember = (value, project, teamMember) => {
    this.updateStateofSelectedProject_forSelectedTeamMemberCheckbox(
      value,
      project,
      teamMember
    );
  };
  updateStateofSelectedProject_forSelectedTeamMemberCheckbox = (
    value,
    project,
    member
  ) => {
    let selectedProject = this.state.selectedProject;
    let selectProject = project;
    let teamMembers;

    let gteProjectId = 0;
    let gteEmployeeNo = 0;

    teamMembers = selectProject.teamMembers?.map((teamMember) => {
      if (teamMember.gteEmployeeNo === member.gteEmployeeNo) {
        let taskData = teamMember?.taskData?.map((taskDataObj) => {
          let timeData = taskDataObj?.timeData?.map((timeDataObj) => {
            let timeEntryData = timeDataObj?.timeEntryData?.map(
              (timeEntryObj) => {
                let selectionChangeValue = {
                  selectionChange: [
                    {
                      timeRecord: timeEntryObj.timeRecordId,
                      timeEntry: [timeEntryObj.timeEntryId],
                    },
                  ],
                };
                this.updateUserSelection(
                  selectProject.gteProjectId,
                  this.state.selectedDate.year,
                  this.state.selectedDate.week,
                  value,
                  selectionChangeValue
                );

                return { ...timeEntryObj, isSelected: value };
              }
            );

            return {
              ...timeDataObj,
              isSelected: value,
              timeEntryData: timeEntryData,
            };
          });
          return { ...taskDataObj, isSelected: value, timeData: timeData };
        });
        gteProjectId = selectProject.gteProjectId;
        gteEmployeeNo = teamMember.gteEmployeeNo;

        return { ...teamMember, isSelected: value, taskData: taskData };
      } else {
        return teamMember;
      }
    });

    selectProject = { ...selectProject, teamMembers: teamMembers };

    let projectList = this.state.projectList?.map((projectObj) => {
      if (projectObj.gteProjectId === selectProject.gteProjectId) {
        return {
          ...projectObj,
          teamMembers: teamMembers,
        };
      }
      return projectObj;
    });

    this.resetDaysCheckbox();

    this.setState({
      item: gteProjectId + "-" + gteEmployeeNo,
      selectedProject: {
        ...selectedProject,
        selectProject: selectProject,
      },
      projectList: projectList,
    });
  };

  handleCheckBoxChangeEventofTaskItem = (value, project, teamMember, task) => {
    this.updateStateofSelectedProject_forSelectedTaskDataCheckbox(
      value,
      project,
      teamMember,
      task
    );
  };
  updateStateofSelectedProject_forSelectedTaskDataCheckbox = (
    value,
    project,
    member,
    task
  ) => {
    let selectedProject = this.state.selectedProject;

    let teamMembers;

    let gteProjectId = 0;
    let gteEmployeeNo = 0;
    let taskId = 0;

    teamMembers = project.teamMembers?.map((teamMember) => {
      let isSelectedTrueFrTeamMember = 1;
      let isSelectedFalseFrTeamMember = 0;
      if (teamMember.gteEmployeeNo === member.gteEmployeeNo) {
        let taskData = teamMember?.taskData?.map((taskDataObj) => {
          let isSelectedTrueFrTask = 1;
          let isSelectedFalseFrTask = 0;
          if (taskDataObj.taskId === task.taskId) {
            let timeData = taskDataObj?.timeData?.map((timeDataObj) => {
              let timeEntryData = timeDataObj?.timeEntryData?.map(
                (timeEntryObj) => {
                  let selectionChangeValue = {
                    selectionChange: [
                      {
                        timeRecord: timeEntryObj.timeRecordId,
                        timeEntry: [timeEntryObj.timeEntryId],
                      },
                    ],
                  };
                  this.updateUserSelection(
                    selectedProject.gteProjectId,
                    this.state.selectedDate.year,
                    this.state.selectedDate.week,
                    value,
                    selectionChangeValue
                  );
                  timeEntryObj.isSelected === value
                    ? (isSelectedFalseFrTask = 1)
                    : (isSelectedTrueFrTask = 0);
                  return { ...timeEntryObj, isSelected: value };
                }
              );

              return {
                ...timeDataObj,
                isSelected: value,
                timeEntryData: timeEntryData,
              };
            });
            gteProjectId = project.gteProjectId;
            gteEmployeeNo = teamMember.gteEmployeeNo;
            taskId = taskDataObj.taskId;
            value === 1
              ? (isSelectedFalseFrTeamMember = 1)
              : (isSelectedTrueFrTeamMember = 0);
            return { ...taskDataObj, isSelected: value, timeData: timeData };
          } else {
            taskDataObj?.timeData?.map((timeDataObj) => {
              timeDataObj?.timeEntryData?.map((timeEntry) => {
                timeEntry.isSelected === 1
                  ? (isSelectedFalseFrTask = 1)
                  : (isSelectedTrueFrTask = 0);
              });
            });

            taskDataObj.isSelected === 1
              ? (isSelectedFalseFrTeamMember = 1)
              : (isSelectedTrueFrTeamMember = 0);

            return taskDataObj;
          }
        });

        return {
          ...teamMember,
          taskData: taskData,
          isSelected:
            isSelectedFalseFrTeamMember === 0
              ? 0
              : isSelectedTrueFrTeamMember === 1
              ? 1
              : 0,
        };
      } else {
        return teamMember;
      }
    });

    let selectProject = { ...project, teamMembers: teamMembers };

    let projectList = this.state.projectList?.map((projectObj) => {
      if (projectObj.gteProjectId === selectProject.gteProjectId) {
        return {
          ...projectObj,
          teamMembers: teamMembers,
        };
      }
      return projectObj;
    });

    this.resetDaysCheckbox();

    this.setState({
      item: gteProjectId + "-" + gteEmployeeNo + "-" + taskId,
      selectedProject: {
        ...selectedProject,

        selectProject: selectProject,
      },
      projectList: projectList,
    });
  };
  closeModelAndUpdateData = () => {
    this.updateProjectObj();
  };
  updateProjectList = (project, year, week) => {
    if (
      year === this.state.selectedDate.year &&
      week === this.state.selectedDate.week
    ) {
      this.setState({
        projectList: this.state.projectList?.map((projectObj) => {
          if (projectObj.gteProjectId === project.gteProjectId) {
            return project;
          }
          return projectObj;
        }),
      });
    }
  };
  updateSelectedProject = (project, year, week) => {
    if (
      year === this.state.selectedDate.year &&
      week === this.state.selectedDate.week
    ) {
      let selectedProject = this.state.selectedProject;

      this.setState({
        selectedProject: { ...selectedProject, selectProject: project },
      });
      return true;
    }
  };
  updateModelStatus = () => {
    this.setState({
      model: false,
      loading: false,
    });
  };

  getBarChartData = () => {
    let dataListUpto7Week = this.createListofWeeks();

    if (this.state.selectedProject.type !== "initial") {
      this.setState({
        setBarChartData: false,
      });
      this.getApprovedNPendingApprovedTime(dataListUpto7Week);
    }
  };
  createListofWeeks = () => {
    let day = this.state.selectedDate?.firstDayofCurrentWeek;
    day = moment(day).add(7, "days").format("YYYY-MM-DD");
    let dataListUpto7Week = [];
    for (let i = 0; i < 8; i++) {
      dataListUpto7Week.push({
        day: day,
        week: moment(day).startOf("isoweek").format("WW"),
        year: moment(day).startOf("isoweek").format("YYYY"),
      });
      day = moment(day).subtract(7, "days").format("YYYY-MM-DD");
    }

    return dataListUpto7Week;
  };

  getApprovedNPendingApprovedTime = (dataListUpto7Week) => {
    let promises = [];
    dataListUpto7Week.map((obj) => {
      promises.push(
        getBarDetails(
          this.state.selectedProject.selectProject.gteProjectId,
          obj.year,
          obj.week,
          this.props.loggedUser,
          obj
        )
      );
      // console.log(promises.length)
    });

    if (promises.length > 0) {
      this.readBarChartReqFetchDataValues(promises);
    }
  };

  readBarChartReqFetchDataValues = (promises) => {
    Promise.all(promises).then((promise) => {
      let dataListUpto7Week = [];
      promise?.map((obj) => {
        dataListUpto7Week?.push(obj);
      });
      if (dataListUpto7Week.length > 0) {
        let selectedProject = this.state.selectedProject;
        selectedProject.selectProject.dataListUpto7Week = dataListUpto7Week;
        this.setState({
          selectedProject: selectedProject,
          setBarChartData: true,
        });
        //  console.log("promise read ",dataListUpto7Week)
        // this.setDataReqForBar();
      }
    });
  };

  renderTeamMembersList = (project) => {
    return project?.teamMembers?.map((teamMember) => (
      <span>
        <li
          onClick={() => this.onClickMemberItemLabel(project, teamMember)}
          //  onClick={() => this.onClickMemberItemLabel(project, teamMember)}
        >
          <div
            className={`ch-list-content ${
              this.state.item ===
              project.gteProjectId + "-" + teamMember.gteEmployeeNo
                ? "active"
                : ""
            }`}
            onClick={
              () => this.onClickMemberItemLabel(project, teamMember)
              // onClick={() => this.onClickProjectItemLabel(project, teamMember,"teamMember")}
            }
            // onKeyDown={(event) => {
            //   this.onClickMemberItemLabel(project, teamMember);
            // }}
            tabIndex={0}
          >
            <div
              className="list-icon"
              onClick={() => {
                this.expandTeamMember(
                  teamMember.isExpand,
                  project.gteProjectId,
                  teamMember
                );
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  this.expandTeamMember(
                    teamMember.isExpand,
                    project.gteProjectId,
                    teamMember
                  );
                }
              }}
              tabIndex={0}
              title={`${teamMember?.isExpand ? "Expanded" : "Collapsed"}`}
            >
              {teamMember?.isExpand ? (
                <span className="arrow down">&#10095;</span>
              ) : (
                <span className="arrow">&#10095;</span>
              )}
            </div>
            <div className="list-text" tabIndex={0} title={teamMember?.name}>
              {" "}
              {teamMember.name}
            </div>
            <div className="list-value">
              <span className="">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={teamMember.isSelected === 1 ? true : false}
                  title={`${
                    teamMember.isSelected === 1 ? "Selected" : "Un Selected"
                  }`}
                  id={teamMember.gteEmployeeNo}
                  disabled={teamMember.isDisable ? true : false}
                  tabIndex={0}
                  onChange={(event) =>
                    this.handleCheckBoxChangeEventOfTeamMember(
                      event.target.checked ? 1 : 0,
                      project,
                      teamMember
                    )
                  }
                  onKeyDown={(event) => {
                    if (event.key === "ArrowRight") {
                      this.setState({
                        move: true,
                      });
                    }
                  }}
                />
              </span>
            </div>
          </div>
        </li>
        {teamMember?.isExpand && (
          <ul>{this.renderTaskList(project, teamMember)}</ul>
        )}
      </span>
    ));
  };

  renderTaskList = (project, teamMember) => {
    return (
      <span>
        {teamMember.taskData?.map((task) => (
          <li
            onClick={() => this.onClickTaskItemLabel(project, teamMember, task)}
            onKeyUp={(event) => {
              this.onClickTaskItemLabel(project, teamMember, task);
              // if (event.key === "Enter") {
              //   this.onClickTaskItemLabel(project, teamMember, task);
              // }
            }}
          >
            <div
              className={`ch-list-content ${
                this.state.item ===
                project.gteProjectId +
                  "-" +
                  teamMember.gteEmployeeNo +
                  "-" +
                  task.taskId
                  ? "active"
                  : ""
              }`}
            >
              <div className="list-icon"></div>

              <div
                className="list-text"
                tabIndex={0}
                onKeyUp={(event) => {
                  this.onClickTaskItemLabel(project, teamMember, task);
                  // if (event.key === "Enter") {
                  //   this.onClickTaskItemLabel(project, teamMember, task);
                  // }
                }}
              >
                {task.taskName} {task.userStatus === "R" ? "(Done)" : " "}
              </div>
              <div className="list-value">
                <span className="">
                  <input
                    tabIndex={0}
                    className="form-check-input"
                    type="checkbox"
                    checked={task.isSelected === 1 ? true : false}
                    id={task.taskId}
                    onChange={(event) =>
                      this.handleCheckBoxChangeEventofTaskItem(
                        event.target.checked ? 1 : 0,
                        project,
                        teamMember,
                        task
                      )
                    }
                    disabled={task.isDisable ? true : false}
                    onKeyDown={(event) => {
                      if (event.key === "ArrowRight") {
                        this.setState({
                          move: true,
                        });
                      }
                    }}
                  />
                </span>
              </div>
            </div>
          </li>
        ))}
      </span>
    );
  };

  render() {
    const { bannerVisible, feedbackVisible } = this.state;
    let projectListStyle = {
      height: "800px",
      overflowX: "hidden",
      overflowY: "scroll",
    };
    return (
      <React.Fragment>
        {bannerVisible ? (
          <Banner
            bannerVisible={(value) => {
              this.setState({ bannerVisible: value });
            }}
          />
        ) : (
          <div>
            {feedbackVisible && (
              <Feedback
                loggedUser={this.props.loggedUser}
                hide={() => {
                  this.setState({
                    toast: false,
                  });
                }}
                feedbackVisible={(value) => {
                  this.setState({
                    feedbackVisible: value,
                  });
                  console.log("value : " + value);
                }}
              ></Feedback>
            )}
          </div>
        )}
        <section>
          <div className="ch-main-nav">
            <div className="week-nav">
              <div
                tabIndex={0}
                className="prev-week"
                onClick={() => {
                  this.pastWeek();
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    this.pastWeek();
                  }
                }}
                title="Navigate to previous week"
              ></div>
              <div
                className="date"
                tabIndex={0}
                title={`${this.state.selectedDate.dateOfFirstDayofCurrentWeek} to ${this.state.selectedDate.dateOfLastDayofCurrentWeek}`}
              >
                {this.state.selectedDate.dateOfFirstDayofCurrentWeek}
                {" to "}
                {this.state.selectedDate.dateOfLastDayofCurrentWeek}
              </div>
              <div
                tabIndex={0}
                className="next-week"
                onClick={() => {
                  this.nextWeek();
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    this.nextWeek();
                  }
                }}
                title="Navigate to next week"
              ></div>
              <div
                className="week-no"
                tabIndex={0}
                title={` ${this.state.selectedDate.year} week ${this.state.selectedDate.week}`}
              >
                {" "}
                <b>
                  {this.state.selectedDate.year} {" Week "}
                </b>
                {this.state.selectedDate.week}
              </div>
              <div className="viewDashboard">
                <a
                  tabIndex={0}
                  title="View Dashboard"
                  target="_blank"
                  className="dashboard-dec text-decoration-none"
                  href="https://app.powerbi.com/groups/me/apps/ee61a295-3eae-413f-ac0f-89e79bb0d06f/reports/a65b3dc5-868b-4c41-9e6c-3c0e70593cf0/ReportSection67548732d7aa46812430?ctid=0d85160c-5899-44ca-acc8-db1501b993b6"
                >
                  View Dashboard
                </a>
              </div>
            </div>

            <div className="nav-actions">
              <button
                tabIndex={0}
                className="btn btn-sm confirm-button"
                title="Confirm Approved Time for Billing"
                disabled={
                  this.state.selectedProject.type === "initial" ||
                  !this.state.setPieChartData ||
                  !this.state.setBarChartData
                }
                onClick={() => {
                  this.handleConfirmApprovedTimeforBillingButton();
                }}
              >
                Confirm Approved Time for Billing
              </button>
              <button
                className="btn btn-sm total-time-of-pending "
                tabIndex={0}
                title={`${
                  this.state.selectedProject?.selectProject
                    ?.totalTimeOfPendingConfirmation
                    ? this.state.selectedProject?.selectProject
                        ?.totalTimeOfPendingConfirmation
                    : "0"
                }`}
              >
                {this.state.selectedProject?.selectProject
                  ?.totalTimeOfPendingConfirmation
                  ? this.state.selectedProject?.selectProject
                      ?.totalTimeOfPendingConfirmation
                  : "0"}
              </button>
            </div>
          </div>
        </section>

        {this.state.projectList?.length > 0 ? (
          <section>
            <div className="ch-actions">
              <div
                className="ch-project-name"
                tabIndex={0}
                title={`${this.state.selectedProject.selectProject.projectName}`}
              >
                {this.state.selectedProject.selectProject.projectName}
              </div>
              <div className="ch-main-actions">
                <button
                  className="btn btn-sm red_btn"
                  onClick={() =>
                    this.handleApproveOrRejectSelectionButtonFuntion("R")
                  }
                  tabIndex={0}
                  title="Reject Selection"
                >
                  Reject Selection
                </button>

                <button
                  className="btn btn-sm green_btn"
                  onClick={() =>
                    this.handleApproveOrRejectSelectionButtonFuntion("A")
                  }
                  tabIndex={0}
                  title="Approve Selection"
                >
                  Approve Selection
                </button>
              </div>
            </div>
            <div className="ch-dynamic-container">
              <div className="ch-tree-list">
                <ul>
                  {this.state.projectList?.map((project) => (
                    <span>
                      <li
                        onClick={() =>
                          this.onClickProjectItemLabel(project, {}, "project")
                        }
                        onKeyDown={(event) => {
                          // console.log(">>>>>>>>>>>>>>>>> ",event.key);
                          this.onClickProjectItemLabel(project, {}, "project");
                        }}
                        tabIndex={0}
                      >
                        <div
                          className={`ch-list-content ${
                            this.state.item === project.gteProjectId
                              ? "active"
                              : ""
                          }`}
                        >
                          <div
                            className="list-icon"
                            tabIndex={0}
                            onClick={() => {
                              this.expandProject(
                                project.isExpand,
                                project.gteProjectId
                              );
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                this.expandProject(
                                  project.isExpand,
                                  project.gteProjectId
                                );
                              }
                            }}
                            title={`${
                              project?.isExpand ? "Expanded" : "Collapsed"
                            }`}
                          >
                            {project?.isExpand ? (
                              <span className="arrow down">&#10095;</span>
                            ) : (
                              <span className="arrow">&#10095;</span>
                            )}
                          </div>
                          <div
                            className="list-text"
                            tabIndex={0}
                            title={project.projectName}
                          >
                            {project.projectName}
                          </div>
                          <div className="list-value">
                            {this.state.totalTimeStatus ? (
                              <div
                                className=""
                                tabIndex={0}
                                onKeyDown={(event) => {
                                  if (event.key === "ArrowRight") {
                                    this.setState({
                                      move: true,
                                    });
                                  }
                                }}
                              >
                                {Math.round(
                                  (project?.totalTime + Number.EPSILON) * 100
                                ) / 100}
                              </div>
                            ) : (
                              <div
                                className="spinner-container"
                                tabIndex={0}
                                title="Loading..."
                              >
                                <div className="loading-spinner"></div>
                              </div>
                            )}
                          </div>
                        </div>
                        {/* {project.isExpand && this.renderTeamM?embersList(project)} */}
                      </li>
                      {project.isExpand && (
                        <ul>{this.renderTeamMembersList(project)}</ul>
                      )}
                    </span>
                  ))}
                </ul>
              </div>
              <div className="ch-calender">
                <TimeApproveMainPage
                  move={this.state.move}
                  updateMoveState={() => {
                    this.setState({
                      move: false,
                    });
                  }}
                  selectedDate={this.state.selectedDate}
                  selectedProject={this.state.selectedProject}
                  loggedUser={this.props.loggedUser}
                  item={this.state.item}
                  handleCheckboxSelectionOfDayCard={(value, day) => {
                    this.handleCheckboxSelectionOfDayCard(value, day);
                  }}
                  handleApproveOrRejectSelectionButtonFuntion={(type) => {
                    this.handleApproveOrRejectSelectionButtonFuntion(type); //------------------------------------------- stop here..-> issue, undefined teamMemberlist
                  }}
                  handleCheckBoxSelectionEventOfItemCard={(
                    value,
                    item,
                    day
                  ) => {
                    this.handleCheckBoxSelectionEventOfItemCard(
                      value,
                      item,
                      day
                    );
                  }}
                  loadingState={this.state.loading}
                />
              </div>
            </div>
          </section>
        ) : (
          <div className="notify">No Data to Display</div>
          //  this.state.error.status?( <div className="notify">{this.state.error?.message}</div>):( <div className="notify">No Data to Display....</div>)
        )}

        <section>
          {this.state.model === true && (
            <ConfirmApprovedTimeforBillingModel
              disabled={this.state.loading}
              selectedDate={this.state.selectedDate}
              selectedProject={this.state.selectedProject}
              loggedUser={this.props.loggedUser}
              item={this.state.item}
              closeModel={() => {
                this.setState({
                  model: false,
                });
              }}
              hide={() => {
                this.setState({
                  model: false,
                });
              }}
              closeModelAndUpdateData={() => {
                this.closeModelAndUpdateData();
              }}
              loadingState={() => {
                this.setState({
                  loading: true,
                });
              }}
            />
          )}
        </section>
        <section>{this.state.loading && <CheckInLoadPopup />}</section>
        <div>
          {this.state.error?.status &&
            this.state.error.message.status !== 404 && (
              <ErrorModel
                loggedUser={this.props.loggedUser}
                errorResponse={this.state.error?.message}
                hide={() => {
                  this.setState({
                    error: {
                      status: false,
                      message: "",
                    },
                  });
                }}
              ></ErrorModel>
            )}
        </div>
      </React.Fragment>
    );
  }
}

export default MainMenu;
